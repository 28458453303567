@tailwind base;
@tailwind components;
@tailwind utilities;

#tube {
  width: 1204px;
  height: 677px;
}

.calendar {
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
}
